.site-header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5em - $skip-to-main-height * 2;
  @include media(min-height 42em) {
    margin-bottom: 8em - $skip-to-main-height * 2;
  }
  @include media(min-height 50em) {
    margin-bottom: 12em - $skip-to-main-height * 2;
  }
}

main {
  padding-top: $skip-to-main-height * 2;
  @include flush-margins(3);
}

.logo {
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-size: 1em;
  margin: 0 $box-padding * 2 0 0;
  a {
    display: block;
    width: 7.5em;
    color: $blue;
    &:hover {
      color: darken($blue, 10%);
    }
    &:active {
      color: darken($blue, 20%);
    }
    @media (prefers-color-scheme: dark) {
      &:hover {
        color: lighten($blue, 7%);
      }
      &:active {
        color: lighten($blue, 14%);
      }
    }
  }
  svg {
    display: block;
    width: 100%;
  }
}

.site-nav {
  li {
    display: inline-block;
    margin-right: 0.5em;
    &:last-child {
      margin-right: 0;
    }
  }
  @supports (display: flex) {
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      margin-right: 1em;
    }
  }
  a[href] {
    text-decoration: none;
    @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
      border-bottom: none;
    }
  }
  a[aria-current] {
    text-decoration: underline;
    text-decoration-thickness: $underline-thickness;
    @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
      text-decoration: none;
      border-bottom: $border-underline-thickness solid;
    }
  }
}


.section-header {
  p {
    margin: 0;
  }
}

.section-title {
  font-size: $h1-font-size;
  line-height: $heading-line-height;
  @include spacing($heading-spacing);
  a[href] {
    border: none;
  }
}

.feeds {
  color: $secondary-font-color;
  color: var(--secondary-font-color);
  a[href] {
    color: inherit;
    text-decoration-color: currentColor;
    @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
      border-color: currentColor;
    }
    &:hover,
    &:focus {
      color: $link-hover-color;
      color: var(--link-hover-color);
    }
    &:active {
      color: $link-active-color;
      color: var(--link-active-color);
    }
  }
}



.author {
  margin: 6em 0;
  font-style: normal;
  a[href] {
    color: inherit;
    text-decoration: none;
    @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
      border-bottom: none;
    }
    &.author-social {
      color: $link-color;
      color: var(--link-color);
    }
    &:hover,
    &:focus {
      color: $link-hover-color;
      color: var(--link-hover-color);
    }
    &:active {
      color: $link-active-color;
      color: var(--link-active-color);
    }
  }
  .author-photo {
    width: 3em;
    border-radius: 2em;
    vertical-align: middle;
    margin-right: 0.25em;
  }
  .author-info {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.375;
  }
}
