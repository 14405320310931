.spritesheet {
  @include hide-visually;
}

.visually-hidden {
  @include hide-visually;
}

.icon {
  display: inline-block;
}

a[href].button {
  text-decoration: none;
  border: $border-underline-thickness solid;
  border-radius: $base-border-radius;
  display: inline-block;
  padding: 0.25em 0.5em 0.375em;
}

#skip-to-main {
  color: white;
  display: block;
  text-align: center;
  position: absolute;
  top: 0.5em;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  height: 1.5em;
  line-height: 1.5em;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  
  &:focus {
    opacity: 1;
    transition: none;
    text-decoration: underline;
    text-decoration-thickness: $underline-thickness;
    text-underline-offset: $underline-offset;
    @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
      text-decoration: none;
      border-bottom: $border-underline-thickness solid;
    }
  }
  
  + .page-wrapper {
    position: relative;
    transition: transform 0.1s ease-in;
  }
  
  &:focus + .page-wrapper {
    transform: translateY($skip-to-main-height);
    transition-timing-function: ease-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    &, & + .page-wrapper {
      transition: none !important;
    }
  }
}

sup.beta {
  font-size: 0.5em;
  font-weight: bold;
  letter-spacing: 0.0625em;
  color: $star-rating-color;
  color: var(--star-rating-color);
  text-transform: uppercase;
}
