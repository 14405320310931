.post {
  margin: 6em 0;
  
  .post-title,
  footer {
    a[href] {
      text-decoration: none;
      @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
        border-bottom: none;
      }
    }
  }
  
  .post-title {
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  
  .text {
    margin: $text-spacing 0;
  }
  
  footer {
    color: $secondary-font-color;
    color: var(--secondary-font-color);
    p {
      margin: 0;
    }
  }
}


.post.links .post-title {
  font-size: 1em;
  margin: 0;
  line-height: $base-line-height;
  a {
    color: inherit;
    padding: 0.25em 0; // avoid unclickable gaps between lines in multi-line links
    .link-title {
      font-weight: bold;
      text-decoration: underline;
      text-decoration-color: $link-color;
      text-decoration-color: var(--link-color);
      text-decoration-thickness: $underline-thickness;
      @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
        text-decoration: none;
        border-bottom-style: solid;
        border-bottom-color: $link-color;
        border-bottom-color: var(--link-color);
        border-bottom-width: $border-underline-thickness;
      }
    }
    .link-domain {
      color: $link-color;
      color: var(--link-color);
      font-style: normal;
      display: inline-flex;
      align-items: baseline;
      max-width: 100%;
      > * {
        white-space: nowrap;
      }
      .url {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &:hover,
    &:focus {
      color: $link-hover-color;
      color: var(--link-hover-color);
    }
    &:active {
      color: $link-active-color;
      color: var(--link-active-color);
    }
    &:hover,
    &:focus,
    &:active {
      * {
        color: currentColor;
        text-decoration-color: currentColor;
        @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
          border-color: currentColor;
        }
      }
    }
  }
}

.post.draft {
  background-color: $draft-background-color;
  background-color: var(--draft-background-color);
  box-shadow: 0 0 0 1em $draft-background-color;
  box-shadow: 0 0 0 1em var(--draft-background-color);
}

.post-figure {
  @include spacing($paragraph-spacing);
  max-width: $text-max-width;
}

main > .post .post-figure {
  max-width: none;
}

img.fill {
  width: 100%;
}

.post-source .icon,
.log-action .icon {
  vertical-align: -7%;
}

.feed {
  margin: 6em 0;
}

.post-location {
  font-style: italic;
}

.feed-archive {
  margin: $text-spacing 0;
  a {
    color: inherit;
  }
  h2 {
    font-size: 1em;
    line-height: $base-line-height;
    margin: 0;
    font-weight: bold;
    a {
      padding: 0.25em 0; // avoid unclickable gaps between lines in multi-line links
    }
  }
  p {
    margin: 0;
    &.excerpt {
      display: inline-block;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.meta {
      color: $secondary-font-color;
      color: var(--secondary-font-color);
    }
  }
}

.pagination {
  .button {
    text-align: center;
    min-width: 8em;
  }
}




$log-media-width: 5em;

.log {
  margin: 6em 0;
  max-width: 35em;
  &.media {
    display: grid;
    grid-template-columns: minmax(2em, $log-media-width) minmax(60%, 1fr);
    grid-gap: 1.5em;
  }

  a[href] {
    text-decoration: none;
    @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
      border-bottom: none;
    }
  }
  p {
    color: $secondary-font-color;
    color: var(--secondary-font-color);
    margin: 0;
  }
  
  .log-content {
    align-self: end;
  }

  .log-media {
    align-self: center;
    width: $log-media-width;
    max-width: 100%;
    margin: 1.5em 0;
    @supports (display: grid) {
      margin: 0;
      order: -1;
    }
  }
}

.post .log {
  margin: 1em 0;
}

// add a little extra space between cover image and following figure, when present
.post .log.media + figure {
  margin-top: 1.5em;
}

.rating {
  letter-spacing: 0.1875em;
  font-size: 1.125em;
  vertical-align: 7%;
  white-space: nowrap;
  .star-1 {
    color: $star-rating-color;
    color: var(--star-rating-color);
  }
  .star-0 {
    color: $base-border-color;
    color: var(--base-border-color);
  }
}
small .rating {
  font-size: 1.25em;
  vertical-align: baseline;
}

.invisible-text {
  // https://github.com/h5bp/html5-boilerplate/issues/1985#issuecomment-394096182
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}


.media-title {
  font-size: 1em;
  line-height: $base-line-height;
  margin: 0;
  
  .rating {
    font-size: 1em;
    vertical-align: baseline;
  }
}

// This needs to be specific to the media layout, 
// to avoid affecting post permalinks:
// h1.media-title {
//   font-size: 1.75em;
//   line-height: $heading-line-height;
//   > * {
//     display: block;
//   }
// }

.media-title a[href] {
  color: inherit;
  border-bottom: 0;
  padding-bottom: 0;
  text-decoration: none;
  .media-title-name {
    text-decoration: underline;
    text-decoration-color: $link-color;
    text-decoration-color: var(--link-color);
    text-decoration-thickness: $underline-thickness;
    @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
      text-decoration: none;
      border-bottom-style: solid;
      border-bottom-color: $link-color;
      border-bottom-color: var(--link-color);
      border-bottom-width: $border-underline-thickness;
    }
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    color: var(--link-hover-color);
  }
  &:active {
    color: $link-active-color;
    color: var(--link-active-color);
  }
  &:hover,
  &:focus,
  &:active {
    * {
      color: currentColor;
      text-decoration-color: currentColor;
      @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
        border-color: currentColor;
      }
    }
  }
}

.media-title-name {
  font-style: normal;
  font-weight: bold;
}

.media-title-year {
  font-weight: normal;
  font-style: normal;
  color: $secondary-font-color;
  color: var(--secondary-font-color);
}

.media-title-meta {
  a[href] {
    color: inherit;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: 1px;
    &:hover {
      text-decoration-style: solid;
    }
  }
}

.log-media {
  > *:nth-child(n) {
    margin: 0;
  }
  a[href] {
    text-decoration: none;
    @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
      border-bottom: none;
    }
  }
  a[href], img {
    display: block;
  }
  img {
    border-radius: 0.25em;
    width: 100%;
    filter: brightness(0.95);
  }
  a[href] img {
    box-shadow: 0 0.25em 1em rgba(0,0,0,0.2);
    transition: all 0.2s ease;
  }
  a[href]:hover img,
  a[href]:focus img {
    transform: translateY(-0.25em);
    box-shadow: 0 0.5em 1.25em rgba(0,0,0,0.2);
  }
}

.spoilers {
  summary {
    color: $secondary-font-color;
    color: var(--secondary-font-color);
    border: $base-border-width solid;
    padding: 0.25em 0.5em 0.375em;
    border-radius: 0.25em;
  }
}

.library {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(8.5em, 1fr));
  grid-gap: 3em 1.5em;
  margin: 3em 0;
  li {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.library-entry {
  display: flex;
  flex-direction: column;
  .library-meta {
    margin-top: 0.5em;
    height: 2em;
    p {
      margin: 0;
    }
  }
  .media-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.75em;
  }
  .rating {
    font-size: 1em;
  }
  .log-media {
    margin: 0;
    order: -1;
    align-self: end;
    max-height: 100%;
    display: flex;
    img {
      width: auto;
      max-height: 100%;
    }
  }
}

.media-header {
  margin-bottom: 6em;
  &.cover {
    display: grid;
    grid-template-columns: minmax(2em, 10em) minmax(60%, 1fr);
    grid-gap: 1.5em;
    align-items: flex-end;
  }
  .media-title {
    font-size: 1.25em;
    line-height: $heading-line-height;
  }
  figure {
    order: -1;
    margin: 0;
  }
}

.media-subhead {
  font-size: 1.25em;
  margin-top: 4.8em; /* 6em / 1.25em */
  & + .feed {
    margin: 3em 0;
  }
}

.tag-list {
  border-top-width: $base-border-width;
  border-top-style: dotted;
  border-top-color: $base-border-color;
  border-top-color: var(--base-border-color);
  padding-top: 1em;
  padding-bottom: 0.5em;
  &:last-of-type {
    border-bottom-width: $base-border-width;
    border-bottom-style: dotted;
    border-bottom-color: $base-border-color;
    border-bottom-color: var(--base-border-color);
    padding-bottom: 1.5em;
  }
  @include media($medium-screen-up) {
    columns: 2;
    column-gap: 2em;
  }
  @include media(min-width 48rem) {
    columns: 3;
  }
}

.feed-list {
  li {
    margin: 2em 0;
    break-inside: avoid;
    li {
      list-style-type: disc;
      margin: 0 0 0 1.5em;
    }
    ul {
      margin: 0.5em 0;
      ul {
        margin: 0;
      }
    }
    p {
      margin: 0;
      max-width: $text-max-width;
    }
  }
}

ul.all-feeds {
  list-style-type: none;
  padding-left: 0;
  max-width: none;
  > li {
    margin: 1.5em 0;
    border-top-width: $base-border-width;
    border-top-style: dotted;
    border-top-color: $base-border-color;
    border-top-color: var(--base-border-color);
    &:last-child {
      border-bottom-width: $base-border-width;
      border-bottom-style: dotted;
      border-bottom-color: $base-border-color;
      border-bottom-color: var(--base-border-color);
      padding-bottom: 0.5em;
    }
  }
  li > p > a:first-child {
    color: $link-color;
    color: var(--link-color);
  }
  p {
    max-width: none;
  }
}

.tag-count {
  color: $secondary-font-color;
  color: var(--secondary-font-color);
}
