.deck {
  max-width: 33em;
  @include spacing(4);
  @include flush-margins(1);
  
  @include media(min-width 40rem) {
    font-size: 1.25em;
  }

  li {
    @include spacing(0.5);
  }
}

#about p:last-child {
  margin-top: 1.5em;
}

@include media(max-width 30rem) {
  #about br {
    display: none;
  }
}

.secondary {
  h2 {
    font-size: 1.25em;
  }
  li {
    @include spacing(0.5);
  }
}
