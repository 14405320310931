$activity-cover-width: 8em;

.activity {
  display: grid;
  grid-template-columns: $activity-cover-width repeat(auto-fill, minmax(35%, 1fr));
  @include media(max-width 30rem) {
    grid-template-columns: 6em repeat(auto-fill, minmax(35%, 1fr));
  }
  grid-column-gap: 1.5em;
  max-width: none;
  margin: 3em 0;
  
  header {
    grid-column: 2 / -1;
    border-top-width: $base-border-width;
    border-top-style: solid;
    border-top-color: $base-border-color;
    border-top-color: var(--base-border-color);
    padding-top: 1em;
    p {
      color: $secondary-font-color;
      color: var(--secondary-font-color);
      margin: 0;
    }
  }
  
  .log-media {
    order: -1;
    grid-row: 1 / 3;
    max-width: $activity-cover-width;
    margin: 1em 0;
    @supports (display: grid) {
      margin: 0;
    }
    img {
      width: 100%;
    }
  }
  
  .post-content {
    grid-column: span 2 / -1;
    margin: 0;
  }
  
  article.post {
    margin: 1em 0;
  }
  
  &.cover .post-content .post-figure:first-child {
    margin-top: 1.5em;
  }
  
  &.no-cover {
    border-top-width: $base-border-width;
    border-top-style: solid;
    border-top-color: $base-border-color;
    border-top-color: var(--base-border-color);
    header {
      grid-column: span 2 / -1;
      border-top: 0;
    }
  }
  
  &.single {
    display: block;
  }

  &.single .post-content .post-figure {
    max-width: none;
    &:first-child {
      margin-top: 1em;
    }
  }
}

.library-year-heading {
  margin-top: 5em;
  padding-top: 1em;
  margin-bottom: 0;
}

.library-year-data {
  color: $secondary-font-color;
  color: var(--secondary-font-color);
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  p:last-child .library-year-data-category:last-child {
    margin-right: 0;
  }
  .separator {
    display: none;
  }
}
.library-year-data-category {
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  margin-right: 1.5em;
  @include media($medium-screen-up) {
    margin-right: 2em;
  }
  margin-top: 1em;
  &[href] {
    text-decoration: none;
    @supports not ((text-decoration-thickness: $underline-thickness) and (text-underline-offset: $underline-offset)) {
      border-bottom: none;
    }
  }
  b {
    font-weight: normal;
    font-size: 1.5em;
  }
  span {
    font-size: 0.75em;
    text-transform: uppercase;
    font-weight: bold;
  }
  &.no-data {
    color: $base-border-color;
    color: var(--base-border-color);
    abbr {
      text-decoration: none;
      border-bottom: none;
      letter-spacing: -0.0625em;
    }
  }
}

.library-heading {
  font-size: 1em;
  text-transform: uppercase;
  margin-top: 5em;
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom-width: $base-border-width;
  border-bottom-style: solid;
  border-bottom-color: $base-font-color;
  border-bottom-color: var(--base-font-color);
  margin-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: $main-background-color;
  background-color: var(--main-background-color);
}

.library-list {
  margin: 0;
  font-size: 0.875em;
  @include media($medium-screen-up) {
    font-size: 1em;
  }
}

.library-item {
  margin: 0;
  padding: 0.75em 0;
  display: flex;
  border-bottom-width: $base-border-width;
  border-bottom-style: dotted;
  border-bottom-color: $base-border-color;
  border-bottom-color: var(--base-border-color);
  .icon {
    vertical-align: -7%;
    color: $secondary-font-color;
    color: var(--secondary-font-color);
  }
  .ellipsis:before {
    content: "⋯";
  }
  .media-title {
    flex-grow: 1;
  }
}

.library-item-content {
  display: flex;
}

.library-item-text {
  display: block;
  width: 100%;
}

.library-item-date {
  color: $secondary-font-color;
  color: var(--secondary-font-color);
  display: inline-block;
  margin-right: 0.75em;
  width: 1.5em;
  width: calc(2ch + 1px);
  font-variant-numeric: lining-nums;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.library-item-meta {
  color: $secondary-font-color;
  color: var(--secondary-font-color);
  text-transform: lowercase;
  display: block;
  min-width: 5.5em;
  flex-shrink: 0;
  .rating {
    font-size: 1em;
    .star-1 {
      color: $star-rating-muted-color;
      color: var(--star-rating-muted-color);
    }
    .star-0 {
      opacity: 0;
    }
  }
  .rating-5 {
    .star-1 {
      color: $star-rating-color;
      color: var(--star-rating-color);
    }
  }
}

.library-item-cover {
  width: 5.25em;
  height: intrinsic;
  margin-right: 1em;
  border-radius: 0.125em;
  box-shadow: 0 0.25em 1em rgba(0,0,0,0.2);
}

.library-item.cover {
  padding: 1.5em 0;
}

@include media($medium-screen-up) {
  .library-item-text {
    display: flex;
    justify-content: space-between;
  }
  .library-item-meta {
    margin-left: 1em;
    .rating .star-0 {
      opacity: 1;
    }
  }
}


.about-ratings {
  summary {
    color: $secondary-font-color;
    color: var(--secondary-font-color);
  }
  h2 {
    font-size: 1em;
    font-weight: bold;
  }
  dl {
    max-width: none;
    div {
      display: flex;
      flex-direction: column;
      @include media(min-width 50rem) {
        flex-direction: row;
      }
    }
  }
  dt {
    flex-grow: 0;
    flex-shrink: 0;
  }
  dd {
    padding: 0;
    margin-left: 2em;
    margin-left: calc(2ch + 1px + 0.75em);
    @include media(min-width 50rem) {
      margin-left: 1em;
    }
  }
  .rating {
    font-size: 1em;
    vertical-align: baseline;
  }
}

.progress {
  width: 5.5em;
  height: $base-line-height * 1em;
  display: flex;
  align-items: center;
}

.progress progress {
  width: 100%;
  height: 0.5em;
  vertical-align: 0.25em;
  margin-right: 0.1875em;
  appearance: none;
  border-width: $base-border-width;
  border-style: solid;
  border-color: $base-border-color;
  border-color: var(--base-border-color);
  border-radius: 0.5em;
  color: $star-rating-muted-color;
  color: var(--star-rating-muted-color);
  background: transparent;
  position: relative;
  overflow: hidden;
  
  &::-webkit-progress-bar {
    background: transparent;
    color: transparent;
    border-radius: 0.5em;
  }
  
  &::-webkit-progress-value {
    background: $star-rating-muted-color;
    background: var(--star-rating-muted-color);
    //border-radius: 0.5em;
  }
  
  &::-moz-progress-bar {
    background: $star-rating-muted-color;
    background: var(--star-rating-muted-color);
    //border-radius: 0.5em;
  }
}
