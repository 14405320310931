.phone-frame {
  display: block;
  width: 100%;
  max-width: 22em;
  border-radius: 1.5em;
  box-shadow: 0 0.5em 1.5em rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;
}

.desktop-frame {
  display: none;
  width: 100%;
  //max-width: 22em;
  border-radius: 0.5em;
  box-shadow: 0 0.5em 1.5em rgba(0, 0, 0, 0.2);
}

.videos {
  perspective: 100em;
  .phone-frame:hover {
    box-shadow: 0.5em 0.5em 1.5em rgba(0, 0, 0, 0.15);
    transform: rotateY(-30deg) rotateX(10deg);
  }
}
