// Add horizontal scrolling to non-responsive wide elements like tables
.overflow-container {
  $overflow-container-padding: 0.75rem;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: $overflow-container-padding 0;
  display: grid;
  grid-template-columns: $overflow-container-padding auto $overflow-container-padding;
  border-width: $base-border-width;
  border-style: solid;
  border-color: $base-border-color;
  border-color: var(--base-border-color);
  border-radius: $base-border-radius;
  &::before,
  &::after {
    content: "";
  }
  @include spacing(2);
  margin-left: $overflow-container-padding * -1;
  margin-right: $overflow-container-padding * -1;
  @include flush-margins(1);
}

// Add responsive resizing to embedded iframes, using 16:9 ratio
.embed-container {
  @include aspect-ratio(16/9, iframe);
  > :not(iframe) {
    display: none;
  }
}

.embed-placeholder {
  display: block;
  overflow: hidden;
  @include aspect-ratio(16/9, img);
  > img {
    transform: scale(1.01);
  }
}

a.embed-placeholder[href] {
  border-bottom: 0;
  padding-bottom: 0;
}

.embed-video {
  overflow: hidden;
  @include aspect-ratio(16/9);
  img {
    object-fit: cover;
    transform: scale(1.01);
  }
  button {
    color: white;
    border: 0;
    border-radius: 0;
    background-color: rgba(0,0,0,0.1);
    transition: 0.2s ease background-color;
    &:hover,
    &:focus {
      background-color: rgba(0,0,0,0.2);
    }
    &:focus {
      box-shadow: inset 0 0 0.375em 0.375em rgba($blue, 0.5);
    }
    .icon {
      filter: drop-shadow(0 0.5em 1em rgba(0, 0, 0, 0.3));
    }
  }
}
